import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import PageLayout from '../components/page-layout'
import StoryText from '../components/story/text'
import SectionWrapper from '../components/section/wrapper'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import { InlineImageFragment } from '../lib/fragments'

class PageText extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, screenSize, location } = this.props

    const page = get(data, 'page')
    const textRows = get(page, 'acf.text_content_page')

    return (
      <PageLayout
        seo={getSEOFromPage(page)}
        location={location}
        showFooter={true}
      >
        <SectionWrapper naturalHeight={true}>
          <div className="section-content">
            <div className="cont">
              <div className="sectionSpacer sectionSpacer--double" />
              <div className="textBlock textBlock--center">
                <h3>{page.title}</h3>
              </div>
              <div className="sectionSpacer sectionSpacer--half" />
              {textRows ? <StoryText rows={textRows} /> : null}
              <div className="sectionSpacer sectionSpacer--double" />
            </div>
          </div>
        </SectionWrapper>
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageText)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
      acf {
        text_content_page {
          __typename
          ... on WordPressAcf_text {
            text
          }
          ... on WordPressAcf_subheading {
            title
            subtitle
          }
          ... on WordPressAcf_image {
            image {
              ...ARYImageInline
            }
          }
        }
      }
    }
  }
`
